import {
  useCallback,
  useEffect,
} from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import {
  activateTutorial,
  completeTutorial,
  loadCompletedTutorials,
  updateTutorial,
  cancelTutorial,
} from '../actions/tutorials';

const useTutorial = (tutorial) => {
  const {
    activeTutorial,
    activeTutorialData,
    completedTutorials,
  } = useSelector((state) => state?.tutorials, shallowEqual);

  const dispatch = useDispatch();

  const activate = useCallback((tutorialData) => {
    dispatch(activateTutorial(tutorial, tutorialData));
  }, [dispatch, tutorial]);
  const update = useCallback((tutorialData) => {
    dispatch(updateTutorial(tutorial, tutorialData));
  }, [dispatch, tutorial]);
  const complete = useCallback(() => {
    dispatch(completeTutorial(tutorial));
  }, [dispatch, tutorial]);
  const cancel = useCallback(() => {
    dispatch(cancelTutorial(tutorial));
  }, [dispatch, tutorial]);

  useEffect(() => {
    const localStorage = window.localStorage.getItem('completedTutorials');
    if (!localStorage) return;
    const parsed = Object.fromEntries(
      Object.entries(JSON.parse(localStorage))
        .map(([key, dateString]) => [key, new Date(Date.parse(dateString))]),
    );
    dispatch(loadCompletedTutorials(parsed));
  }, [dispatch]);

  useEffect(() => {
    window.localStorage.setItem('completedTutorials', JSON.stringify(completedTutorials));
  }, [completedTutorials]);

  return {
    state: {
      isActive: activeTutorial === tutorial,
      data: activeTutorial === tutorial ? activeTutorialData : {},
      completedAt: completedTutorials[tutorial],
    },
    activate,
    update,
    complete,
    cancel,
  };
};

export default useTutorial;
