import React, { useState, useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { addDays } from 'date-fns';

import { Box } from '@tmap/mmm-style-guide/src/Box';
import { Favorite, FavoriteBorder } from '@tmap/mmm-style-guide/src/Icon';
import { Tooltip } from '@tmap/mmm-style-guide/src/Tooltip';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import { alpha } from '@tmap/mmm-style-guide/src/alpha';
import { Popover } from '@tmap/mmm-style-guide/src/Popover';
import nestGet from '@tmap/mmm-core/nestGet';
import stopEvent from '@tmap/mmm-style-guide/src/util/stopEvent';
import { toKey, types } from '@tmap/mmm-core/entityKey';
import { IconButton } from '@tmap/mmm-style-guide/src/IconButton';

import {
  addUserFavorite as addUserFavoriteAction,
  removeUserFavorite as removeUserFavoriteAction,
} from '../actions/user';
import ResponsiveIconButton from './responsiveIconButton';
import useRequireAuth from '../hooks/useRequireAuth';
import analytics from '../lib/analytics';
import useTutorial from '../hooks/useTutorial';
import useRefInView from '../hooks/useRefInView';

const TutorialTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  zIndex: 1150,
  '& .MuiTooltip-tooltip': {
    maxWidth: '160px',
    textAlign: 'center',
  },
}));

const TutorialPopover = styled(Popover)(() => ({
  zIndex: 1150,
  '& .MuiPopover-paper': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    backgroundImage: 'none',
    minWidth: '0px',
    minHeight: '0px',
    width: '0px',
    height: '0px',
    overflow: 'visible',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const TutorialVFX = styled(Box)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  background: `radial-gradient(
    29.51% 29.51% at 50% 50%,
    ${theme.palette.favorite.main} 37.5%,
    ${alpha(theme.palette.favorite.main, 0.9)} 78.57%,
    ${alpha(theme.palette.favorite.veryLight, 0.45)} 100%
  )`,
  width: '96px',
  height: '96px',
  '@keyframes breathe': {
    '0%': {
      width: '80px',
      height: '80px',
      opacity: 0.9,
      animationDelay: '0.1s',
      animationTimingFunction: 'ease-out',
      animationDuration: '1.2s',
    },
    '50%': {
      width: '96px',
      height: '96px',
      opacity: 1,
      animationDelay: '0.1s',
      animationTimingFunction: 'ease-out',
      animationDuration: '0.8s',
    },
    '100%': {
      width: '80px',
      height: '80px',
      opacity: 0.9,
      animationDelay: '0.1s',
      animationTimingFunction: 'ease-out',
      animationDuration: '1.2s',
    },
  },
  animationName: 'breathe',
  animationDuration: '2.5s',
  animationIterationCount: 'infinite',
}));

const TutorialIconButton = styled(IconButton)(({ theme }) => ({
  color: `${theme.palette.favorite.veryLight} !important`,
  '& .MuiTypography-root': {
    color: `${theme.palette.favorite.veryLight} !important`,
  },
}));

const FavoritedIcon = styled(Favorite)(({ theme }) => ({
  color: theme.palette.favorite.main,
}));

// this component is usually rendered in dark theme
const UnfavoritedIcon = styled(Favorite)(({ theme }) => ({
  strokeWidth: '1px',
  stroke: theme.palette.text.primary,
  fill: alpha(theme.palette.inverted.contrastText, 0.4),
}));

function FavoriteButton(props) {
  const {
    authStore,
    entity,
    entityKey,
    isFavorite,
    variant = 'text',
    color = 'favorite',
    fontSize = 'medium',
    twoToneHeart = false,
    removeUserFavorite,
    addUserFavorite,
    Button: ButtonComponent = ResponsiveIconButton,
    tooltip,
    children,
    ...buttonProps
  } = props;
  const router = useRouter();
  const { isAuthenticated, requireAuth } = useRequireAuth();
  const favorites = useSelector((state) => state?.users?.favorites || [], shallowEqual);

  const url = {
    [types.INCENTIVE]: `/get-paid/${entity.slug.current}`,
    [types.COMMUNITY]: `/moving-living/${entity.slug.current}`,
  }[entity._type] || router.asPath;

  const favoritable = {
    [types.INCENTIVE]: 'Program',
    [types.COMMUNITY]: 'Community',
    [types.CONTRIBUTION]: 'Post',
  }[entity._type];

  // base delay of viewing the button before tutorial may show
  const tutorialBaseDelay = 5_000;
  // prevent tutorial from showing if the user is touching or scrolling
  const tutorialTouchDelay = 1_000;

  const tutorial = useTutorial('favorite');
  const [recentlyClicked, setRecentlyClicked] = useState(false);
  const tutorialBaseTimeout = useRef();
  const { ref: inViewRef, inView } = useRefInView({
    threshold: 1,
    rootMargin: '-32px -16px -32px -16px',
  });

  const isTutorialIncomplete = (
    !tutorial.state.completedAt
    || addDays(tutorial.state.completedAt, 14).getTime() <= Date.now()
  );
  const canActivateTutorial = (
    inView
    && !isFavorite
    && favorites.length === 0
    && !recentlyClicked
    && !tutorial.state.isActive
  );
  const isTutorialMode = tutorial.state.isActive && tutorial.state.data.entityKey === entityKey;
  const showPopovers = Boolean(isTutorialMode && inView && inViewRef.current);

  const handleFavorite = (e) => {
    stopEvent(e);
    requireAuth(() => {
      if (isFavorite) {
        removeUserFavorite(entityKey);
      } else {
        addUserFavorite(entityKey);
      }
    }, { returnTo: `/favorites/continue?id=${entityKey}&url=${url}` });
  };
  const handleTutorialFavorite = (e) => {
    stopEvent(e);
    tutorial.complete();
    handleFavorite(e);
  };
  const handleTutorialClose = (e) => {
    stopEvent(e);
    tutorial.complete(); // they did not decide to like it
  };

  // effect for tutorial touch delay
  useEffect(() => {
    let timeout;
    const listener = () => {
      setRecentlyClicked(true);
      timeout = setTimeout(() => {
        setRecentlyClicked(false);
      }, tutorialTouchDelay);
    };
    window.addEventListener('mousedown', listener);
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('mousedown', listener);
      window.removeEventListener('scroll', listener);
      clearTimeout(timeout);
    };
  }, []);

  // effect for tutorial base delay
  useEffect(() => {
    // once the element enters view, begin staring timer
    // not checking isTutorialIncomplete since this involves Date.now() (changes every render)
    if (canActivateTutorial) {
      tutorialBaseTimeout.current = setTimeout(() => {
        // they've been looking at the page and still for a while
        // show the tutorial
        if (canActivateTutorial && isTutorialIncomplete) {
          analytics.track('like_prompt', { entityKey });
          tutorial.activate({ entityKey });
        }
      }, tutorialBaseDelay);
    } else if (tutorialBaseTimeout.current) {
      clearTimeout(tutorialBaseTimeout.current);
    }
    return () => {
      clearTimeout(tutorialBaseTimeout.current);
    };
  }, [canActivateTutorial, entityKey, isTutorialIncomplete, tutorial]);

  // on unmount, make sure to cancel the tutorial if it happened to start
  // this will make the tutorial reappear if the user changes routes during it
  useEffect(() => () => {
    if (isTutorialMode) {
      tutorial.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTutorialMode]);

  const favoritedIcon = (
    <FavoritedIcon fontSize={fontSize} />
  );
  const unfavoritedIcon = twoToneHeart ? (
    <UnfavoritedIcon fontSize={fontSize} />
  ) : (
    <FavoriteBorder fontSize={fontSize} />
  );

  let RegularButton = (
    <ButtonComponent
      ref={inViewRef}
      icon={isFavorite ? favoritedIcon : unfavoritedIcon}
      variant={variant}
      color={color}
      onClick={handleFavorite}
      {...buttonProps}
    >
      {isFavorite ? favoritedIcon : unfavoritedIcon}
      {children && (
        <Box sx={{ marginLeft: 1 }}>{children}</Box>
      )}
    </ButtonComponent>
  );
  // only allow tooltiping the button when the tutorial is inactive
  if (tooltip && !isTutorialMode) {
    RegularButton = (
      <Tooltip title={tooltip} aria-label='tooltip' placement='top'>
        {RegularButton}
      </Tooltip>
    );
  }
  const TutorialButtton = (
    <TutorialIconButton
      icon={isFavorite ? favoritedIcon : unfavoritedIcon}
      variant='text'
      color='inverted'
      onClick={handleTutorialFavorite}
      {...buttonProps}
    >
      {unfavoritedIcon}
      {children && (
        <Box sx={{ marginLeft: 1 }}>{children}</Box>
      )}
    </TutorialIconButton>
  );

  const tutorialTitle = isAuthenticated ? (
    `Save this ${favoritable} to your profile`
  ) : (
    `Login to save this ${favoritable} to your profile`
  );

  return (
    <>
      {RegularButton}
      <TutorialTooltip
        title={tutorialTitle}
        aria-label={tutorialTitle}
        open={showPopovers}
        placement='top'
        arrow
        slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, 32] } }] } }}
        PopperProps={{ anchorEl: inViewRef.current }}
        disableTouchListener
      >
        <TutorialPopover
          open={showPopovers}
          anchorEl={inViewRef.current}
          anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
          transformOrigin={{ vertical: 'center', horizontal: 'center' }}
          onClose={handleTutorialClose}
        >
          <TutorialVFX>
            {TutorialButtton}
          </TutorialVFX>
        </TutorialPopover>
      </TutorialTooltip>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  // making the component api backwards compatible
  const entity = ownProps.incentive ? ownProps.incentive : ownProps.entity;
  const entityKey = toKey(entity._type, entity._id);
  return {
    authStore: nestGet(state, ['authStore']),
    isFavorite: (nestGet(state, ['users', 'favorites']) || []).includes(entityKey),
    entity,
    entityKey,
  };
}
export default connect(mapStateToProps, {
  addUserFavorite: addUserFavoriteAction,
  removeUserFavorite: removeUserFavoriteAction,
})(FavoriteButton);
