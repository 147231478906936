import {
  ACTIVATE_TUTORIAL,
  UPDATE_TUTORIAL,
  COMPLETE_TUTORIAL,
  CANCEL_TUTORIAL,
  LOAD_COMPLETED_TUTORIALS,
} from '../reducers/tutorials';

export function activateTutorial(tutorial, tutorialData) {
  return {
    type: ACTIVATE_TUTORIAL,
    payload: {
      tutorial,
      tutorialData,
    },
  };
}

export function updateTutorial(tutorial, tutorialData) {
  return {
    type: UPDATE_TUTORIAL,
    payload: {
      tutorial,
      tutorialData,
    },
  };
}

export function completeTutorial(tutorial) {
  return {
    type: COMPLETE_TUTORIAL,
    payload: {
      tutorial,
    },
  };
}

export function cancelTutorial(tutorial) {
  return {
    type: CANCEL_TUTORIAL,
    payload: {
      tutorial,
    },
  };
}

export function loadCompletedTutorials(completedTutorials) {
  return {
    type: LOAD_COMPLETED_TUTORIALS,
    payload: {
      completedTutorials,
    },
  };
}
